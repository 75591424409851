import { TFunction } from "i18next";
import { updateError } from "~/redux/error-slice";
import { logout, updateCurrentUser } from "~/redux/current-user-slice";
import { getAppFormattedErrors } from "~/utils/request";
import { AxiosError } from "axios";
import { authState } from "~/constants";
import Sentry from "~/sentry";
import store from "~/redux/store";
import { Auth } from "aws-amplify";

type dispatch = typeof store.dispatch;

const subdomain = window.location.host.split(".")[0];

export const validateCurrentLoginSession = (user, dispatch: dispatch) => {
  Auth.currentSession().catch((err) => {
    if (subdomain === "navia-app") {
      window.location.assign("https://app.naviabenefits.com/#/login");
    } else if (user.id) {
      dispatch(logout());
    }
  });
};

export const handleSignIn = (payload: any, dispatch: dispatch, t: TFunction) => {
  dispatch(updateCurrentUser(payload));
  if (!payload.is_active) {
    dispatch(
      updateError({
        code: 403,
        message: t("errors.deactivatedAccountMsg"),
        description: t("errors.deactivatedAccountDetails"),
      })
    );
  }
};

export const handleAuthError = (
  err: AxiosError,
  dispatch: dispatch,
  t: TFunction,
  user
) => {
  Sentry.withScope(function (scope) {
    scope.setExtra("error_details", JSON.stringify(err.response?.data || err.response));
    Sentry.captureException(err);
  });
  const isNetworkError = err.message === "Network Error";
  const code = isNetworkError ? 500 : err.response?.status;
  const isUnAuthorized = code === 401;
  if (isUnAuthorized) {
    validateCurrentLoginSession(user, dispatch);
  }
  const isInvalidInvite = code === 400;
  const message = isInvalidInvite
    ? t("errors.invalidInvite")
    : getAppFormattedErrors(err);
  dispatch(
    updateError({
      code,
      message: [500, 401].includes(code) ? null : message,
    })
  );
};

export const getAuthObject = (data) => ({
  authState: authState.SignedIn,
  authData: {
    attributes: data.attributes,
    signInUserSession: {
      idToken: {
        jwtToken: data.signInUserSession.idToken.jwtToken,
      },
    },
  },
});

export const listener = async ({
  payload,
  setIsFetchingState,
  queryClient,
  dispatch,
  originalUrl,
  navigate,
}) => {
  if (payload.event === "userSignedIn" && payload.data?.signInUserSession?.idToken) {
    // show the loading spinner
    setIsFetchingState(true);
    // trigger the api_schema query
    queryClient.resetQueries({ queryKey: ["api_schema"] });
    // extract Auth data
    const userAuthData = getAuthObject(payload.data);
    dispatch(updateCurrentUser(userAuthData));
    navigate(originalUrl || "/");
  } else if (payload.event === "signOut") {
    navigate("/login");
    dispatch(logout());
    queryClient.cancelQueries();
    queryClient.removeQueries({
      predicate: (query) => query.queryKey[0] !== "api_schema",
    });
  }
};
