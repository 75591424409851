import MenuIcon from "./burger-menu-icon";

const Navbar = ({ setSidebarOpen }) => {
  return (
    <div className="sticky top-0 z-30 flex h-[48px] flex-shrink-0 bg-navbar-bg sm:hidden">
      <button
        type="button"
        className="-ml-1 px-4 focus:outline-none md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuIcon className="h-6 w-6 text-navbar-text" aria-hidden="true" />
      </button>
    </div>
  );
};

export default Navbar;
