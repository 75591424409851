import { NavLink } from "react-router-dom";
import { clsx } from "clsx";
import { Body2 } from "../../components/typography";

export const NavMenuItem = ({ item }) => {
  return (
    <NavLink
      to={item.href}
      className={({ isActive }) =>
        clsx(
          isActive
            ? "bg-navbar-bg_selected text-navbar-text_selected"
            : "text-navbar-text hover:text-navbar-text_hovered",
          "group flex items-center whitespace-nowrap rounded-md px-2 py-2 lg:px-3"
        )
      }
    >
      <item.icon className="mr-4 h-6 w-6 flex-shrink-0" aria-hidden="true" />
      <Body2 className="font-medium text-inherit">{item.name}</Body2>
    </NavLink>
  );
};

export const LogoutButton = ({ item }) => {
  return (
    <button
      key={item.name}
      onClick={item.onClick}
      className="group flex w-full items-center whitespace-nowrap rounded-md px-2 py-2 text-base font-medium text-navbar-text hover:bg-navbar-item_selected hover:text-navbar-text_hovered"
    >
      <item.icon className="mr-4 ml-1 h-6 w-5 flex-shrink-0" aria-hidden="true" />
      <Body2 className="text-inherit">{item.name}</Body2>
    </button>
  );
};
