import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const providerConfigSlice = createSlice({
  name: "providerConfig",
  initialState,
  reducers: {
    addConfig: (state, action) => {
      return action.payload;
    },
  },
});

export const providerConfigSelector = (state) => state.providerConfig;
export const { addConfig } = providerConfigSlice.actions;

export default providerConfigSlice.reducer;
