import { QueryClient, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";
import { OpenApiPathParams } from "~/types/openapi-params";

interface FetchDistributionRequestProps
  extends OpenApiPathParams<
    "get",
    "/api/v1/admin/distribution-requests/{distribution_request_id}"
  > {}

const fetchDistributionRequest = async ({
  distributionRequestId,
}: FetchDistributionRequestProps) => {
  const { data } = await openapi.get(
    "/api/v1/admin/distribution-requests/{distribution_request_id}",
    {
      params: { path: { distribution_request_id: distributionRequestId } },
    }
  );

  return data;
};

const distributionRequestQuery = ({
  distributionRequestId,
}: FetchDistributionRequestProps) => ({
  queryKey: ["admin", "distribution-requests", distributionRequestId],
  queryFn: () => fetchDistributionRequest({ distributionRequestId }),
  useErrorBoundary: true,
  select: (data) => {
    if (data.status === "rejected") {
      return {
        ...data,
        status: "declined",
      };
    }
    return data;
  },
});

const fetchDistributionRequestExpectedSales = async ({
  distributionRequestId,
}: FetchDistributionRequestProps) => {
  const { data } = await openapi.get(
    "/api/v1/admin/distribution-requests/{distribution_request_id}/expected-sales",
    {
      params: { path: { distribution_request_id: distributionRequestId } },
    }
  );

  return data;
};

const distributionRequestExpectedSalesQuery = ({
  distributionRequestId,
}: FetchDistributionRequestProps) => ({
  queryKey: ["admin", "distribution-requests", distributionRequestId, "expected-sales"],
  queryFn: () => fetchDistributionRequestExpectedSales({ distributionRequestId }),
  useErrorBoundary: false,
});

export const distributionRequestLoader = (queryClient: QueryClient) => {
  return async ({ params }) => {
    const query = distributionRequestQuery({ distributionRequestId: params.id });

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};

export const useDistributionRequest = ({
  distributionRequestId,
}: FetchDistributionRequestProps) => {
  return useQuery(distributionRequestQuery({ distributionRequestId }));
};

export const useDistributionRequestExpectedSales = ({ distributionRequestId }) => {
  return useQuery(distributionRequestExpectedSalesQuery({ distributionRequestId }));
};

export const useCustodianEncodedFile = ({ distributionRequestId, onError }) => {
  return useQuery({
    queryKey: [
      "admin",
      "distribution-requests",
      { distributionRequestId },
      "custodian_file",
    ],
    queryFn: () =>
      openapi
        .get("/api/v1/admin/distribution-requests/{distribution_request_id}/encoded", {
          params: {
            path: {
              distribution_request_id: distributionRequestId,
            },
          },
        })
        .then((res) => res.data),
    enabled: !!distributionRequestId,
    ...(onError ? { onError } : {}),
  });
};
