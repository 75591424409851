import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { providerConfigSelector } from "~/redux/provider-config-slice";

export function useTitle(title) {
  const { t } = useTranslation();
  const providerConfig = useSelector(providerConfigSelector);
  const translatedTitle = t("common:metaTitles.admin.general", {
    pageTitle: title,
    providerName: providerConfig.name,
    interpolation: { escapeValue: false },
  });

  useEffect(() => {
    if (!title) {
      document.title = t("common:metaTitles.employer.provider", {
        providerName: providerConfig.name,
        interpolation: { escapeValue: false },
      });
      return;
    }

    document.title = translatedTitle;
  }, [providerConfig.name, t, title, translatedTitle]);
}
