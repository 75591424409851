export const ContributionType = {
  preTax: "pretax",
  roth: "roth",
};

export const PayrollLineItemType = {
  preTax: "participant_pre_tax",
  roth: "participant_roth",
  employerMatch: "employer_safe_harbor_match",
  otherEarnings: [
    "employee_earnings_pre_tax",
    "employee_earnings_roth",
    "employee_earnings_safe_harbor_match",
    "employee_earnings_qnec",
  ],
};

export const FundTypes = {
  dateFund: "dateFund",
  ownFunds: "ownFunds",
};

export const FundPackageNames = {
  quarter: "3m",
  year: "1y",
  fiveYear: "5y",
  tenYear: "10y",
};
