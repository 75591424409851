import { createSlice } from "@reduxjs/toolkit";
import merge from "lodash/merge";
import { localStorageKeySuffix } from "~/constants";

export const initialState = {};

export const currentUserSlice = createSlice({
  name: "current_user",
  initialState,
  reducers: {
    updateCurrentUser: (state, action) => {
      state = merge(state, action.payload);
    },
    updateAgreements: (state, action) => {
      state.agreements = action.payload;
    },
    // This should be kept here to trigger logout action in the rootReducer
    logout: () => {
      // clear data-grid tables state in localStorage
      const localStorageKeys = Object.keys(localStorage);
      localStorageKeys
        .filter((k) => k.endsWith(localStorageKeySuffix))
        .map((k) => localStorage.removeItem(k));
    },
  },
});

// Actions are generated for each reducer
export const { updateCurrentUser, updateAgreements, logout } = currentUserSlice.actions;

export default currentUserSlice.reducer;
