import twMerge from "~/utils/tw-merge";

function Divider({ className = "" }) {
  return (
    <div
      className={twMerge("my-6 w-full border-t border-secondary-light_3", className)}
    />
  );
}

export default Divider;
