import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import TungstenOutlinedIcon from "@mui/icons-material/TungstenOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import SavingsIcon from "@mui/icons-material/SavingsOutlined";
import BusinessIcon from "@mui/icons-material/BusinessOutlined";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";

interface ThemeProps {
  name: string;
  id: string;
  logo: string;
  cssClass: string;
  hideFromDemo?: boolean;
}

export const AVAILABLE_THEMES: { [key: string]: ThemeProps } = {
  penelopeEmployee: {
    name: "Penelope employee",
    id: "penelopeEmployee",
    logo: "penelope",
    cssClass: "theme-employee",
    hideFromDemo: true,
  },
  penelopeEmployer: {
    name: "Penelope employer",
    id: "penelopeEmployer",
    logo: "penelope",
    cssClass: "theme-employer",
    hideFromDemo: true,
  },
  demo: {
    name: "Light blue",
    id: "demo",
    logo: "demo",
    cssClass: "theme-demo",
  },
  navia: {
    name: "Navia",
    id: "navia",
    logo: "navia",
    cssClass: "theme-navia",
  },
  square: {
    name: "Square",
    id: "square",
    logo: "square",
    cssClass: "theme-square",
  },
  bankOfAmerica: {
    name: "Bank of America",
    id: "bankOfAmerica",
    logo: "bankOfAmerica",
    cssClass: "theme-bank-of-america",
  },
  acmeBenefits: {
    name: "ACME Benefit Solutions",
    id: "acmeBenefits",
    logo: "acmeBenefits",
    cssClass: "theme-acme-benefits",
  },
};

export const getDemoThemes = () =>
  Object.values(AVAILABLE_THEMES).filter((theme) => !theme.hideFromDemo);

export const getDashboardFooter = ({
  channelSource = "penelope",
  isBlackout = false,
  t,
}) => {
  const footerLinksMap = {
    penelope: [
      {
        label: t("dashboard:dashboard.employer.footer.privacy"),
        href: "https://penelope.co/privacy-policy",
      },
      {
        label: t("dashboard:dashboard.employer.footer.termsConditions"),
        href: "https://penelope.co/terms-services",
      },
      {
        label: t("dashboard:dashboard.employer.footer.recordkeepingDisclosure"),
        href: "https://penelope.co/recordkeeping-disclosure",
      },
    ],
    navia: [
      {
        label: t("dashboard:dashboard.employer.footer.privacy"),
        href: "https://penelope.co/navia-privacy-policy",
      },
      {
        label: t("dashboard:dashboard.employer.footer.termsConditions"),
        href: "https://penelope.co/navia-privacy-policy#tos",
      },
      {
        label: t("dashboard:dashboard.employer.footer.recordkeepingDisclosure"),
        href: "https://penelope.co/navia-recordkeeping-disclosure",
      },
    ],
  }[channelSource || "penelope"];
  return isBlackout
    ? [
        ...footerLinksMap,
        {
          label: t("dashboard:dashboard.employer.footer.support"),
          href: "https://support.penelope.co/knowledge/participants",
        },
      ]
    : footerLinksMap;
};

export const ICONS = {
  blocked: {
    icon: <PauseOutlinedIcon style={{ fill: "red" }} />,
    color: "red",
  },
  review: {
    icon: <GppMaybeOutlinedIcon style={{ fill: "orange" }} />,
    color: "orange",
  },
  pending: {
    icon: <PendingOutlinedIcon style={{ fill: "grey" }} />,
    color: "grey",
  },
  working: {
    icon: <TungstenOutlinedIcon style={{ fill: "#1976d2" }} />,
    color: "#1976d2",
  },
  invited: {
    icon: <MarkEmailReadIcon style={{ fill: "green" }} />,
    color: "green",
  },
  success: {
    icon: <CheckCircleOutlinedIcon style={{ fill: "green" }} />,
    color: "green",
  },
  activated: {
    icon: <OnlinePredictionIcon style={{ fill: "green" }} />,
    color: "green",
  },
  failure: {
    icon: <CancelOutlinedIcon style={{ fill: "red" }} />,
    color: "red",
  },
  terminated: {
    icon: <DeleteIcon style={{ fill: "red" }} />,
    color: "red",
  },
  participant_pre_tax: {
    icon: <AccountBalanceIcon style={{ fill: "purple" }} />,
    color: "purple",
  },
  participant_roth: {
    icon: <SavingsIcon style={{ fill: "gray" }} />,
    color: "gray",
  },
  employer_safe_harbor_match: {
    icon: <BusinessIcon style={{ fill: "brown" }} />,
    color: "brown",
  },
  admin: {
    icon: <AdminPanelSettingsOutlinedIcon style={{ fill: "blue" }} />,
    color: "blue",
  },
  owner: {
    icon: <SupervisorAccountOutlinedIcon style={{ fill: "red" }} />,
    color: "red",
  },
};

export const FUN_VIDEO = "https://www.youtube.com/watch?v=KqzAfCkIaMI"; // After Onboarding Success
export const SCHEDULE_A_DEMO_LINK = "https://meetings-eu1.hubspot.com/joshua-cordero";

export const UUID_NAMESPACE = "1b671a64-40d5-491e-99b0-da01ff1f3341";

export const getProviderSupportArticles = (provider = "penelope") => {
  const articles = {
    penelope: {
      blackoutPeriod: "https://support.penelope.co/knowledge/blackout-period",
      rolloverOptions: "https://support.penelope.co/knowledge/your-rollover-options",
      retirementProjections:
        "https://support.penelope.co/knowledge/retirement-income-projections",
      planAdministration: "https://support.penelope.co/knowledge/plan-administration-for",
      participants: "https://support.penelope.co/knowledge/participants",
    },
  };
  return articles[provider];
};
