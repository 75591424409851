import { Auth } from "aws-amplify";

const noMFAWhitelist = `${import.meta.env.VITE_NO_MFA_WHITELIST}`.split(",") || [];
export const shouldSkipMFA = (username) => {
  if (username) {
    return import.meta.env.VITE_SKIP_MFA === "true" || noMFAWhitelist.includes(username);
  } else {
    return import.meta.env.VITE_SKIP_MFA === "true";
  }
};

export const signIn = (username, password, signInOpts) => {
  if (shouldSkipMFA(username)) {
    return signInOpts
      ? Auth.signIn({
          ...signInOpts,
          validationData: {
            ...signInOpts.validationData,
            SKIP_MFA: "yes",
          },
        })
      : Auth.signIn(username, password, { SKIP_MFA: "yes" });
  } else {
    return signInOpts ? Auth.signIn(signInOpts) : Auth.signIn(username, password);
  }
};

export const signUp = (props, invitationCode) => {
  const clientMetadata = {
    clientMetadata: { AUTO_CONFIRM_USER: "yes", invitationCode },
  };
  return Auth.signUp({
    ...props,
    ...(shouldSkipMFA(props.username) ? clientMetadata : {}),
  });
};

export const signOut = () => Auth.signOut();
