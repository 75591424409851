export const NaviaLogoSmall = (props) => {
  // to get a unique id per instance
  const id = Date.now();
  return (
    <svg
      key={props.origin}
      viewBox="0 0 260 260"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient
          id={`smallLogo_aa_${id}`}
          x1=".002%"
          x2="99.999%"
          y1="50.25%"
          y2="49.881%"
        >
          <stop stopColor="#45C2CC" offset={0} />
          <stop stopColor="#2BAEB7" offset={0.413} />
          <stop stopColor="#008C95" offset={1} />
        </linearGradient>
        <linearGradient
          id={`smallLogo_bb_${id}`}
          x1="6.705%"
          x2="103.53%"
          y1="89.556%"
          y2="20.181%"
        >
          <stop stopColor="#F26827" offset={0} />
          <stop stopColor="#F47A23" offset={0.196} />
          <stop stopColor="#FAA419" offset={0.715} />
          <stop stopColor="#FCB415" offset={0.982} />
        </linearGradient>
        <linearGradient
          id={`smallLogo_cc_${id}`}
          x1="-4.079%"
          x2="92.391%"
          y1="4.471%"
          y2="89.025%"
        >
          <stop stopColor="#AC2228" offset={0} />
          <stop stopColor="#A52429" offset={0.35} />
          <stop stopColor="#92282B" offset={0.86} />
          <stop stopColor="#8B2A2C" offset={1} />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="m226.57 144.78.012-.382C147.776 90.238.002 191.302.002 191.302s124.67 90.513 206.79 62.17c25.566-7.13 44.5-30.402 44.902-58.285.272-20.67-9.65-39.053-25.117-50.407zm-6.462 79.008c-15.997 15.741-41.602 15.45-57.172-.64-15.57-16.103-15.242-41.906.764-57.64 15.998-15.73 41.59-15.442 57.158.656 15.584 16.085 15.25 41.88-.75 57.624"
          fill={`url(#smallLogo_aa_${id})`}
          transform="translate(2)"
        />
        <path
          d="M189.48.313s-89.433 127.51-59.459 210.06c7.625 25.737 31.446 44.494 59.637 44.443 20.876-.033 39.324-10.386 50.528-26.198h.382C294.033 148.068 189.48.318 189.48.318zm30.632 223.48c-15.997 15.741-41.602 15.45-57.172-.64-15.571-16.103-15.242-41.906.764-57.64 15.998-15.73 41.59-15.442 57.158.656 15.584 16.085 15.25 41.88-.75 57.624"
          fill={`url(#smallLogo_bb_${id})`}
          transform="translate(2)"
        />
        <path
          d="m251.3 185.32-.058-.257c-17.45-94.04-193.38-127.07-193.38-127.07s24.156 152.14 102.27 190.19c23.137 13.039 52.96 9.968 72.978-9.479 14.777-14.402 21.08-34.417 18.188-53.383z"
          fill={`url(#smallLogo_cc_${id})`}
          transform="translate(2)"
        />
        <path
          d="M220.76 222.4c-15.231 14.996-39.618 14.718-54.449-.61-14.83-15.332-14.516-39.912.729-54.893 15.235-14.982 39.609-14.707 54.436.626 14.84 15.318 14.525 39.885-.716 54.877"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
