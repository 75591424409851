import { CSSProperties, FC, HTMLAttributes, useRef } from "react";
import store from "~/redux/store";
import twMerge from "~/utils/tw-merge";

import "../css/loading.css";

/**
 * NOTE:
 * This component does not use Tailwind utility classes because
 * it is sometimes rendered in parts of the app where Tailwind
 * is not available (e.g. the super-admin section).
 */

interface LoadingProps extends HTMLAttributes<HTMLDivElement> {
  neutralColor?: boolean;
  fullScreen?: boolean;
}

export const Loading: FC<LoadingProps> = ({
  title = "Loading…",
  className = "",
  neutralColor = false,
  fullScreen = false,
  ...props
}) => {
  const user = store.getState().currentUser;
  const mountTime = useRef(Date.now());
  const mountDelay = -(mountTime.current % 1000);

  return (
    <div
      className={twMerge(
        "loading-spinner-container",
        /**
         * `text-text-primary-main` is indeed a Tailwind class.
         * However, it is only used if the user is logged in,
         * in which case Tailwind is definitely loaded.
         */
        !neutralColor && !!user.role && "text-text-primary-main",
        fullScreen && "loading-spinner-full-screen",
        className
      )}
      title={title}
      {...props}
    >
      <svg
        className="loading-spinner"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        style={{ "--spinner-delay": `${mountDelay}ms` } as CSSProperties}
      >
        <circle
          className="loading-spinner-circle"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="loading-spinner-path"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
};
