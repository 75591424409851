import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { MutationCache, QueryClient } from "react-query";
import { meQueryKey } from "~/hooks/use-auth";
import Sentry from "~/sentry";
import { getAppFormattedErrors, getFormattedErrors } from "~/utils/request";
import { OpenApiError } from "./openapi-client";

const invalidateMeQuery = () => queryClient.invalidateQueries(meQueryKey);

const decideRetryValue = (failureCount: number, error: any) => {
  if (failureCount > 1) return false;
  if (error?.response?.status === 401 || error?.code === 401) {
    return true;
  }
  if (
    !error?.response?.status ||
    (error?.response?.status >= 400 && error?.response?.status <= 500)
  ) {
    return false;
  }
  return true;
};

const isSuperAdminError = (source) => source === "super-admin";
const isGlobalError = (type) => type !== "local";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      staleTime: 10000,
      retry: decideRetryValue,
      onError: (err: AxiosError & OpenApiError) => {
        const code = err.response?.status || err.code;
        const data = err.response?.data || err.detail;
        if (code === 403 || code === 401) {
          invalidateMeQuery();
        }
        Sentry.withScope(function (scope) {
          scope.setExtra("error_details", JSON.stringify(data));
          Sentry.captureException(err);
        });
      },
    },
    mutations: {
      retry: decideRetryValue,
    },
  },
  mutationCache: new MutationCache({
    onSuccess: (_data, _variables, _context, mutation: any) => {
      if (mutation.meta?.success?.msg) {
        toast.success(mutation.meta.success?.msg);
      }
    },
    onError: (err: AxiosError & OpenApiError, _variables, _context, mutation: any) => {
      const code = err.response?.status || err.code;
      const data = err.response?.data || err.detail;
      if (code === 403 || code === 401) {
        invalidateMeQuery();
      }
      if (isGlobalError(mutation.meta?.error?.type)) {
        let errorsMessage = "";
        if (isSuperAdminError(mutation.meta?.error?.source)) {
          errorsMessage = getFormattedErrors(err);
        } else {
          errorsMessage = getAppFormattedErrors(err);
        }
        toast.error(errorsMessage);
      }
      Sentry.withScope(function (scope) {
        if (data) {
          scope.setExtra("error_details", JSON.stringify(data));
        }
        Sentry.captureException(err);
      });
    },
  }),
});
