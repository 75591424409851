import { QueryClient, UseQueryOptions, useQuery } from "react-query";
import apiInstance from "~/services/axios-instance";

const getAdminRolloverRequestDetails = async (entityId: string) => {
  const { data } = await apiInstance.get(`/admin/rollover-requests/${entityId}`);

  return data;
};

const adminRolloverRequestDetailsQuery = (entityId: string): UseQueryOptions => {
  return {
    queryKey: ["superuser", "rollover-requests", entityId, "view"],
    queryFn: () => getAdminRolloverRequestDetails(entityId),
  };
};

export const adminRolloverRequestDetailsLoader = (queryClient: QueryClient) => {
  return async ({ params }) => {
    const query = adminRolloverRequestDetailsQuery(params.id);

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};

export const useAdminRolloverRequestDetails = (entityId: string) => {
  return useQuery<any>(adminRolloverRequestDetailsQuery(entityId));
};
