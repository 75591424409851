import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FC, useEffect } from "react";
import { Navigate, useRouteError } from "react-router-dom";
import { currentUserSelector } from "~/redux/selectors";
import { isDevEnvironment, checkIfNetworkError } from "~/utils";
import { useOriginalUrl } from "~/routes/use-original-url";
import DefaultLayout from "~/layouts/default-layout";
import { Error } from "~/components";

type ErrorBoundaryProps = {
  redirectUnAuthorized?: boolean;
};

const ErrorBoundary: FC<ErrorBoundaryProps> = ({ redirectUnAuthorized = false }) => {
  const { t } = useTranslation();
  const error: any = useRouteError();
  const isDevEnv = isDevEnvironment();
  const user: { [key: string]: any } = useSelector(currentUserSelector);
  const isNetworkError = checkIfNetworkError(error);
  const { url: loginUrlWithOriginalUrl } = useOriginalUrl({ pathname: "/login" });
  let message = t([
    `errors.statusCodes.${error.status || error.response?.status || 500}.title`,
  ]);
  let description = t([
    `errors.statusCodes.${error.status || error.response?.status || 500}.description`,
  ]);
  let stack = "";
  if (isDevEnv) {
    message = isNetworkError ? "Network error" : error.message;
    description = error.description;
    stack = error.stack;
  }

  useEffect(() => {
    if (error.response?.status !== 401) {
      Sentry.captureException(error);
    }
  }, [error]);

  if (redirectUnAuthorized && !user.id) {
    return <Navigate to={loginUrlWithOriginalUrl} />;
  }

  return (
    <DefaultLayout showProgressCircle={false}>
      <Error
        code={isNetworkError ? "network" : error.status || error.response?.status || 500}
        message={message}
        description={description}
        stack={stack}
        canGoHome={false}
      />
    </DefaultLayout>
  );
};

export default ErrorBoundary;
