import * as React from "react";

const CloseIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.4244 16.7176L3.28223 2.57544L2.57512 3.28255L16.7173 17.4247L17.4244 16.7176Z"
      fill="#F7F7F5"
    />
    <path
      d="M16.7173 2.57539L2.5752 16.7175L3.2823 17.4246L17.4244 3.2825L16.7173 2.57539Z"
      fill="#F7F7F5"
    />
  </svg>
);

export default CloseIcon;
