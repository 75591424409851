import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import size from "lodash/size";

function useNavigator({ tabsArray, tabId, stepId, basePath }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabs, setTabs] = useState([...tabsArray]);

  const initialTabIndex = tabs.findIndex((t) => t.id === tabId);
  const tabIndx = Math.max(initialTabIndex, 0);
  const initialStepIndex = tabs[tabIndx].steps.findIndex((s) => s.id === stepId);
  const stepIndx = Math.max(initialStepIndex, 0);

  const progressCircleIndex = tabs
    .filter((t) => t.showInProgressTracker)
    .findIndex((t) => t.id === tabId);

  const onContinue = () => {
    setTabs((prev) => {
      prev[tabIndx].steps[stepIndx].isVisited = true;
      return [...prev];
    });

    if (stepIndx < tabs[tabIndx].steps.length - 1) {
      const nextStp =
        size(tabs[tabIndx].steps) > 1 ? tabs[tabIndx].steps[stepIndx + 1].id : "";
      navigate(`/${basePath}/${tabs[tabIndx].id}/${nextStp}` + location.search);
    } else if (tabIndx < tabs.length - 1) {
      const nxtTab = tabIndx >= 0 ? tabs[tabIndx + 1] : tabs[0];
      const nextStep = size(nxtTab.steps) > 1 ? nxtTab.steps[0].id : "";
      if (nextStep) {
        navigate(`/${basePath}/${nxtTab.id}/${nextStep}` + location.search);
      } else {
        navigate(nxtTab.id + location.search);
      }
    }
  };

  const onBack = () => {
    if (stepIndx === 0 && tabIndx === 0) {
      navigate("/");
    } else if (stepIndx > 0) {
      const prevStp = tabs[tabIndx].steps[stepIndx - 1].id;
      navigate(`${tabs[tabIndx].id}/${prevStp}` + location.search);
    } else {
      const prevTab = tabs[Math.max(tabIndx - 1, 0)];
      const lastStp = prevTab.steps[prevTab.steps.length - 1].id;
      if (prevTab.steps.length > 1) {
        navigate(`${prevTab.id}/${lastStp}` + location.search);
        return;
      }
      navigate(lastStp + location.search);
    }
  };

  const onForward = () => {
    const lastTabIndex = tabs.length - 1;
    // When forward on last tab and step
    if (lastTabIndex === tabIndx) {
      navigate("/");
      return;
    }

    let nextTab = tabs[tabIndx];
    let nextStep = tabs[tabIndx].steps[0];
    const lastStepIndex = tabs[tabIndx].steps.length - 1;

    if (stepIndx < lastStepIndex) {
      // Steps Forward
      nextTab = tabs[tabIndx];
      nextStep = nextTab.steps[stepIndx + 1];
    } else {
      // Tabs forward
      nextTab = tabs[tabIndx + 1];
      nextStep = nextTab.steps[0];
    }

    // Disable forwaring if step not visited
    if (!nextStep.isVisited) {
      return;
    }

    if (nextTab?.steps.length > 1) {
      navigate(`/${basePath}/${nextTab.id}/${nextStep.id}` + location.search);
    } else {
      navigate(`/${basePath}/${nextTab.id}` + location.search);
    }
  };

  const activeTab = tabs[tabIndx];
  const activeStep = tabs[tabIndx].steps[stepIndx].id;

  const onProceed = () => {
    if (
      initialTabIndex >= 0 &&
      activeTab?.steps.find((s) => s.id === activeStep)?.isVisited
    ) {
      return;
    }
    const lastVisitedTab =
      tabs.find((t) => t.steps.some((s) => !s.isVisited)) || tabs.slice(-1)[0];
    navigate(lastVisitedTab.id + location.search);
  };

  return {
    tabs,
    setTabs,
    onContinue,
    onBack,
    onForward,
    onProceed,
    activeTab,
    activeStep,
    tabIndx,
    stepIndx,
    progressCircleIndex,
  };
}

export default useNavigator;
