import createClient, { Middleware } from "openapi-fetch";
import { paths } from "~/types/openapi-schema";
import getApiHeaders from "./api-headers";

export interface OpenApiError {
  code: number;
  status: number;
  detail: string;
  [x: string]: any
}

const throwOnError: Middleware = {
  async onResponse(res) {
    if (!res.ok) {
      const body = res.headers.get("content-type")?.includes("json")
        ? await res.json()
        : await res.text();
      body.code = res.status;
      body.status = res.status;
      throw body;
    }
    return undefined; // exit the middleware
  },
  async onRequest(req) {
    const headers = await getApiHeaders();
    Object.entries(headers).forEach(([key, value]) => req.headers.set(key, value));
    return req;
  },
};

const client = createClient<paths>({
  baseUrl:
    import.meta.env.VITE_API_BASE_URL?.replace("/api/v1", "") || "http://localhost:8888",
  credentials: "include",
});

client.use(throwOnError);

export const openapi = {
  get: client.GET,
  post: client.POST,
  put: client.PUT,
  delete: client.DELETE,
  patch: client.PATCH,
  options: client.OPTIONS,
  trace: client.TRACE,
  head: client.HEAD,
};
