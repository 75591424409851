import { combineReducers, configureStore } from "@reduxjs/toolkit";
import manageAdmins from "./manage-admins-slice";
import currentUser from "./current-user-slice";
import errorSlice from "./error-slice";
import leadFunnelSlice from "./lead-funnel-slice";
import providerConfigSlice from "./provider-config-slice";

const combinedReducer = combineReducers({
  manageAdmins,
  currentUser,
  error: errorSlice,
  leadFunnelData: leadFunnelSlice,
  providerConfig: providerConfigSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "current_user/logout") {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
});
