import { QueryClient, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";
import { OpenApiFetchOptions } from "~/types/openapi-params";

/**
 * Payroll
 */

interface FetchAdminPayrollProps {
  payrollId: string;
  fetchOptions?: OpenApiFetchOptions<"get", "/api/v1/admin/payrolls/{u_id}">;
}

export const fetchAdminPayroll = async ({
  payrollId,
  fetchOptions = {},
}: FetchAdminPayrollProps) => {
  const { data } = await openapi.get("/api/v1/admin/payrolls/{u_id}", {
    ...fetchOptions,
    params: {
      path: {
        u_id: payrollId,
      },
    },
  });
  return data;
};

interface AdminPayrollQueryProps {
  payrollId: string;
}

const adminPayrollQuery = ({ payrollId }: AdminPayrollQueryProps) => ({
  queryKey: ["superuser", "payroll", { payrollId }],
  queryFn: () => fetchAdminPayroll({ payrollId }),
  enabled: !!payrollId,
});

export const useAdminPayroll = ({ payrollId }: AdminPayrollQueryProps) => {
  return useQuery(
    adminPayrollQuery({
      payrollId,
    })
  );
};

const getAdminPayrollOrders = (data) => ({
  data: [...data.ach_orders, ...data.buy_orders],
});

const adminPayrollOrdersQuery = ({ payrollId }: AdminPayrollQueryProps) => ({
  queryKey: ["superuser", "payroll", { payrollId }],
  queryFn: () => fetchAdminPayroll({ payrollId }),
  enabled: !!payrollId,
  select: getAdminPayrollOrders,
});

export const useAdminPayrollOrders = ({ payrollId }: AdminPayrollQueryProps) => {
  return useQuery(
    adminPayrollOrdersQuery({
      payrollId,
    })
  );
};

export const adminPayrollLoader = (queryClient: QueryClient) => {
  return async ({ params }: { params: { id: string } }) => {
    const query = adminPayrollQuery({ payrollId: params.id });

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};

/**
 * Payroll receipt
 */

const fetchAdminPayrollReceipt = async (payrollId: number | string) => {
  const { data } = await openapi.get("/api/v1/admin/payrolls/{id}/receipt", {
    params: {
      path: { id: Number(payrollId) },
    },
  });

  return data;
};

export const useAdminPayrollReceipt = ({ payrollId }) => {
  return useQuery({
    queryKey: ["superuser", { payrollId }, "payroll", "receipt"],
    queryFn: () => fetchAdminPayrollReceipt(payrollId),
    enabled: !!payrollId,
  });
};
