import { Toaster } from "react-hot-toast";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Alert from '@mui/joy/Alert';

export function AdminCustomToaster() {
  return (
    <Toaster
      position="top-right"
      containerStyle={{
        position: "fixed",
        top: 10,
        right: 40,
      }}
      reverseOrder={false}
      toastOptions={{
        duration: 3000,
        success: {
          icon: "",
        },
      }}
    >
      {(toast) => {
        if (toast.type === "error") {
          return (
            <Alert
              sx={{ minWidth: "24rem" }}
              startDecorator={<WarningIcon />}
              variant="soft"
              color="danger"
            >
              {toast.message}
            </Alert>
          );
        }
        if (toast.type === "success") {
          return (
            <Alert
              sx={{ minWidth: "24rem" }}
              startDecorator={<CheckCircleIcon />}
              variant="soft"
              color="success"
            >
              {toast.message}
            </Alert>
          );
        }
        return (
          <Alert sx={{ minWidth: "24rem" }} variant="soft" color="neutral">
            {toast.message}
          </Alert>
        );
      }}
    </Toaster>
  );
}
