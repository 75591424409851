import React from "react";
import { Body2 } from "./typography";
import Button from "./form/button/new-button";
import { ReactComponent as CloseIcon } from "../assets/images/close-svgrepo-com.svg";
import { useBanner } from "./dashboard/context/banner-context";

const classes = {
  container: "flex bg-banner-bg p-8 text-banner-text",
  text: "flex flex-grow items-center text-banner-text",
};

function Banner({ variant: theme = "employer" }) {
  const { state } = useBanner();
  const currentMessage = state[0];
  const Icon = currentMessage.icon || null;

  return (
    <div className={classes.container}>
      {Icon && <Icon className="mr-3" />}
      <Body2 className={classes.text}>{currentMessage.message}</Body2>
      <div className="flex items-center">
        {currentMessage.actions &&
          currentMessage.actions.map((action, index) => (
            <Button
              key={index}
              className="ml-4"
              color={theme}
              variant={action.variant}
              onClick={action.onClick}
            >
              {action.label}
            </Button>
          ))}
        {currentMessage.onClose && (
          <CloseIcon
            className="ml-4 h-4 w-4 shrink-0 cursor-pointer"
            onClick={currentMessage.onClose}
          />
        )}
      </div>
    </div>
  );
}

export default Banner;
