import { Auth } from "aws-amplify";

const getApiHeaders = async () => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const subdomain = window.location.host.split(".")[0];

  return {
    Authorization: `Bearer ${token}`,
    "X-Provider-Id": subdomain === "navia-app" ? "navia" : "penelope",
  };
};

export default getApiHeaders;
