import { useSelector } from "react-redux";
import { authState } from "../constants";
import { currentUserSelector } from "~/redux/selectors";
import DefaultLayout from "../layouts/default-layout";
import Error from "../components/error";

const rolesValidator = {
  superuser: (user) => user.is_superuser,
  admin: (user) => user.role === "admin" || user.role === "owner" || user.is_superuser,
  user: (user) => user.role === "user" || user.is_superuser,
};

const ProtectedRoute = ({ allowedRole = "user", children }) => {
  const currentUser = useSelector(currentUserSelector);

  const isAuthenticated =
    currentUser.authState === authState.SignedIn &&
    rolesValidator[allowedRole](currentUser);
  if (!isAuthenticated) {
    return (
      <DefaultLayout showProgressCircle={false}>
        <Error code={401} />
      </DefaultLayout>
    );
  }
  return children;
};

export default ProtectedRoute;
