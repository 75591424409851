import * as yup from "yup";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { US_STATE_OPTIONS } from "~/constants";

export const phoneSchema = yup
  .string()
  .test("is-possible-phone-number", "Invalid phone number", (value) => {
    return !!value ? isPossiblePhoneNumber(value) : true;
  })
  .nullable(true);

export const einSchema = yup
  .string()
  .matches(/^\d{2}-\d{7}$/, "Must be a number following this format : xx-xxxxxxx")
  .nullable(true);

export const DropdownSchema = yup
  .mixed()
  .when({
    is: (v) => typeof v === "number",
    then: yup.number().positive().integer(),
    otherwise: yup.string().nullable(true),
  })
  .nullable(false);

export const usZipCodeSchema = yup
  .string()
  .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Invalid Zip Code")
  .nullable(true);

export const addressSchema = yup.object({
  state: yup
    .mixed()
    .oneOf(
      US_STATE_OPTIONS.map((item) => item.value),
      "Re-select your state"
    )
    .required("This field is required"),
  address: yup
    .string()
    .required("This field is required")
    .max(32, "Must be less than 33 characters"),
  city: yup.string().required("This field is required"),
  zip: usZipCodeSchema.required("This field is required"),
  is_primary: yup.boolean().default(true).required(),
  country_code: yup.string().default("US").required(),
});

export const supportSchema = yup.object({
  subject: yup.string().nullable(true).required(),
  message: yup.string().nullable(true).required(),
});

export const qdiaSchema = yup.object({
  ticker: yup.string().nullable(false).required(),
  start_date: yup.number().required().min(1900).max(9999),
  end_date: yup
    .number()
    .required()
    .min(0)
    .max(9999)
    .test("min", function (value) {
      const start_date = this.parent.start_date;
      if (value <= start_date) {
        return false;
      } else {
        return true;
      }
    }),
});
