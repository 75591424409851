import { type SheetProps } from "@mui/joy";
import { useTheme, Theme } from "@mui/joy/styles";

/**
 * This is a temporary solution for a limitation in Joy UI with
 * TypeScript. Unlike Material's `useTheme()` hook, Joy's does
 * not allow you to specify a generic return type, so custom
 * values are not recognized by TypeScript.
 */

interface JoyTheme extends Theme {
  sidebar: {
    variant: SheetProps["variant"];
    color: SheetProps["color"];
    width: {
      open: number;
      closed: number;
      closedXs: number;
    };
  };
}

export const useJoyTheme = () => {
  const theme = useTheme() as JoyTheme;

  return theme;
};
