import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Divider, Loading } from "~/components";
import { TextInput } from "~/components/form";
import Button from "~/components/form/button/new-button";
import apiInstance from "~/services/axios-instance";

export const EMAILS_PREVIEWER = () => {
  const { id: channelSource } = useParams();
  const { t } = useTranslation();
  const [isAuth, setIsAuth] = useState(false);

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, touchedFields },
  } = useForm({
    mode: "onChange",
  });

  const { username, password } = watch();

  const credentials = btoa(`${username}:${password}`);

  const { data: emails, isLoading } = useQuery({
    queryFn: () =>
      apiInstance
        .get(`/preview/emails/${channelSource}`, {
          headers: { Authorization: `Basic ${credentials}` },
        })
        .then((res) => res.data),
    queryKey: ["emails", channelSource],
    staleTime: Infinity,
    useErrorBoundary: true,
    enabled: isAuth,
  });

  const onSubmit = async ({ username, password }) => {
    setIsAuth(true);
  };

  if (!isAuth) {
    return (
      <div className="flex justify-center items-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mx-auto flex w-full flex-col px-3 md:max-w-2xl"
        >
          <TextInput
            label={t("auth.username")}
            register={register("username")}
            error={touchedFields.username && errors.username}
            className="mb-7"
          />
          <TextInput
            label={t("auth.password")}
            type="password"
            register={register("password")}
            error={touchedFields.password && errors.password}
            className="mb-3"
          />
          <div className="mt-auto">
            <Button
              color="employer"
              variant="primary"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {t("form.submit")}
            </Button>
          </div>
        </form>
      </div>
    );
  }

  if (isLoading) {
    return <Loading className="h-[70dvh] text-text-primary-main" />;
  }

  return (
    <div className="w-full">
      {emails.map(({ template, alias }, i) => {
        return (
          <>
            <div key={`${alias}-${i}`} dangerouslySetInnerHTML={{ __html: template }} />
            <Divider />
          </>
        );
      })}
    </div>
  );
};
