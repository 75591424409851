import React from "react";

export default function Image({
  src,
  className = "",
  alt = "",
  width,
  height,
}) {
  return (
    <img
      src={src}
      className={`${className}`}
      alt={alt}
      width={width}
      height={height}
    />
  );
}
