import isUndefined from "lodash-es/isUndefined";
import Image from "./image";

export default function Icon({
  src,
  prefixLabel,
  suffixLabel,
  onClick,
  className = "",
  imgClassName = "h-4 mr-3",
  disabled = false,
}) {
  return (
    <div
      className={`flex items-center pen-icon ${className} ${
        !isUndefined(onClick) ? "cursor-pointer" : ""
      } ${disabled ? "disabled" : ""}`}
      onClick={onClick}
    >
      {prefixLabel && <span>{prefixLabel}</span>}
      <Image src={src} className={`${imgClassName}`} />
      {suffixLabel && <span>{suffixLabel}</span>}
    </div>
  );
}
