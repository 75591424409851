import axios from "axios";
import qs from "qs";
import getApiHeaders from "./api-headers";

export const BASE_URL =
  import.meta.env.VITE_API_BASE_URL || "http://localhost:8888/api/v1";

const apiInstance = axios.create({
  baseURL: BASE_URL,
  paramsSerializer: (params) => {
    return qs.stringify(params, { skipNulls: true, indices: false });
  },
});

apiInstance.defaults.withCredentials = true;
apiInstance.interceptors.request.use(async (config) => {
  try {
    const headers = await getApiHeaders();
    Object.entries(headers).forEach(([key, value]) => config.headers.set(key, value));
  } catch (err) {
    console.error(err);
  }
  return config;
});

export default apiInstance;
