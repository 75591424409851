const appConfig = {
  Auth: {
    region: import.meta.env.VITE_AWS_COGNITO_REGION,
    userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_AWS_COGNITO_WEB_CLIENT_ID,
  },
};

const naviaAppConfig = {
  Auth: {
    region: import.meta.env.VITE_AWS_COGNITO_REGION,
    userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_AWS_COGNITO_NAVIA_WEB_CLIENT_ID,

    oauth: {
      domain: "pen-production.auth.us-east-1.amazoncognito.com",
      scope: ["phone", "email", "openid"],
      redirectSignIn: "https://navia-app.penelope.co",
      redirectSignOut: "https://navia-app.penelope.co",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

const getCognitoConfig = (subdomain) =>
  subdomain === "navia-app" ? naviaAppConfig : appConfig;

export { getCognitoConfig };
