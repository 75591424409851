import { QueryClient, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";

const fetchAlertGroup = async (alertTypeId: string) => {
  const { data } = await openapi.get("/api/v1/admin/alert-groups/{alert_type_id}", {
    params: {
      path: {
        alert_type_id: Number(alertTypeId),
      },
    },
  });

  return data;
};

const alertGroupQuery = (alertTypeId: string) => ({
  queryKey: ["alert-group", { alertTypeId }],
  queryFn: () => fetchAlertGroup(alertTypeId),
  enabled: !!alertTypeId,
  useErrorBoundary: true,
});

export const alertGroupLoader = (queryClient: QueryClient) => {
  return async ({ params }) => {
    const query = alertGroupQuery(params.alertTypeId);

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};

export const useAlertGroup = (alertTypeId: string) => {
  return useQuery(alertGroupQuery(alertTypeId));
};
