import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Button from "./form/button/new-button";
import { signOut } from "../helpers";
import { useLocation } from "react-router-dom";

export default function ErrorBoundaryFallback({
  code = null,
  message = null,
  description = null,
  canGoHome = true,
  isInvalidPath = false,
  resetError,
}) {
  const { t } = useTranslation();
  const goHome = () => {
    window.location.reload();
    window.location.href = "/";
  };

  const defaultStatusCode = code || 500;

  const location = useLocation();
  const errorLocation = useRef(location.pathname);
  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetError();
    }
  }, [location.pathname, resetError]);

  return (
    <div className="flex h-screen items-center">
      <div className="m-auto block w-full max-w-xl text-center text-text-secondary-main">
        {code && <h1 className="mb-6 text-6xl font-thin">{code}(k)</h1>}
        <h2 className="mb-6 text-3xl">
          {message ||
            t([
              `errors.statusCodes.${defaultStatusCode}.title`,
              "errors.pageNotFound.title",
            ])}
        </h2>
        <span className="mb-6 whitespace-pre-line text-sm">
          {description ||
            t([
              `errors.statusCodes.${defaultStatusCode}.description`,
              "errors.pageNotFound.description",
            ])}
        </span>
        <div className={`mt-8 flex justify-center gap-5 ${isInvalidPath && "invisible"}`}>
          {canGoHome && (
            <Button color="employer" variant="tertiary" onClick={goHome}>
              {t("common:general.goHome")}
            </Button>
          )}
          <Button color="employer" variant="tertiary" onClick={signOut}>
            {t("common:general.signout")}
          </Button>
        </div>
      </div>
    </div>
  );
}
