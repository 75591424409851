import throttle from "lodash/throttle";
import { useLayoutEffect, useState } from "react";

export const useWindowSize = (wait = 0) => {
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: null,
    height: null,
  });

  useLayoutEffect(() => {
    const handleResize = throttle(() => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, wait);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [wait]);

  return size;
};
