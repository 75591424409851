import { createSelector } from "reselect";

export const adminsSelector = createSelector(
  (state) => state.manageAdmins.admins,
  (data) => data
);

export const currentUserSelector = createSelector(
  (state) => state.currentUser,
  (data) => data
);

export const errorSelector = createSelector(
  (state) => state.error,
  (data) => data
);

export const leadFunnelDataSelector = createSelector(
  (state) => state.leadFunnelData,
  (data) => data
);
