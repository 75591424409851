import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Sentry from "./sentry";
import "./i18n-fallback";

const fallbackInstance = i18n.cloneInstance({
  fallbackLng: "en",
  defaultNS: "_fallback_translations",
});

const loadedFallbackKeys = {};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["querystring", "cookie", "localStorage", "path", "subdomain"],
      caches: ["localStorage", "cookie"],
    },
    react: {
      useSuspense: true,
      bindI18nStore: "added",
    },
    ns: ["common"],
    defaultNS: ["common"],
    backend: { queryStringParams: { hash: process.env.hash } },
    fallbackLng: "en",
    debug: true,
    saveMissing: true,
    missingKeyHandler: async (ngs, ns, key, fallbackValue, updateMissing, options) => {
      // This is to avoid running the async handler if the key is repeated. for example in the table rows
      // It will slow the rendering when it happens but this will be reported to sentry and fixed directly
      if (loadedFallbackKeys[key]) {
        return;
      }
      loadedFallbackKeys[key] = key;
      Sentry.captureException(new Error("Missing i18n key"), {
        extra: { ngs, ns, key, fallbackValue, updateMissing, options },
      });
      const fallbackTranslation = await fallbackInstance.getResource(
        ngs[0],
        "_fallback_translations",
        key
      );
      await i18n.addResource(ngs[0], ns, key, fallbackTranslation, options);
    },
  });

export default i18n;
