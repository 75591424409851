import React from "react";
import { clsx } from "clsx";
import twMerge from "~/utils/tw-merge";

function S1({ color = "primary", children, className = "", ...props }) {
  return (
    <h6
      className={twMerge(
        "text-base font-medium leading-5",
        clsx({
          "text-text-secondary-main": color === "primary",
          "text-text-secondary-light_1": color === "secondary",
        }),
        className
      )}
      {...props}
    >
      {children}
    </h6>
  );
}

export default S1;
