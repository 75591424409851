import { createContext, useContext, useState } from "react";

const ModalContext = createContext();

const ModalContextProvider = ({ children }) => {
  const [modal, setModal] = useState(null);

  const closeModal = () => setModal(null);

  return (
    <ModalContext.Provider value={{ modal, setModal, closeModal }}>
      {modal}
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

export default ModalContextProvider;
