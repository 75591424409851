import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const i18nFallback = i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["querystring", "cookie", "localStorage", "path", "subdomain"],
      caches: ["localStorage", "cookie"],
    },
    react: {
      useSuspense: true,
    },
    ns: ["_fallback_translations"],
    defaultNS: ["_fallback_translations"],
    fallbackNS: ["_fallback_translations"],
    backend: { queryStringParams: { hash: process.env.hash } },
    fallbackLng: "en",
    debug: true,
  });
