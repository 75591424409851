import add from "date-fns/add";
import format from "date-fns/format";
import { formatCurrency } from ".";
import { ReactComponent as AccountIcon } from "../assets/images/side-menu-employee/account.svg";
import { ReactComponent as InvestmentsIcon } from "../assets/images/side-menu-employee/investments.svg";
import { ReactComponent as DocumentsIcon } from "../assets/images/side-menu-employee/documents.svg";
import { ReactComponent as SupportIcon } from "../assets/images/side-menu-employee/support.svg";
import { ReactComponent as MyAccountIcon } from "../assets/images/side-menu-employee/my-account.svg";
import { ReactComponent as PayrollIcon } from "../assets/images/side-menu-employer/payroll.svg";

export const getEmployeeContributionId = (individual, type) => {
  const employeeContribution =
    individual?.account.employee_retirement_plans[0].employee_contributions.find(
      (p) => p.type === type
    ) || {};
  return employeeContribution.id;
};

export const getEmployeeRetirementPlanId = (individual) => {
  return individual?.account.employee_retirement_plans?.[0]?.id;
};

const fullEmployerMatchPercent = 0.06;
export const getIsTakingFullAdvantage = (totalContribution) =>
  totalContribution >= fullEmployerMatchPercent;

export const getEmployeeDefaultSecurity = (securities, plans) => {
  if (!securities || !plans) return {};
  const defaultSecurityId = plans[0].elections[0].security_id;
  const defaultSecurity = securities.find(
    (security) => security.id === defaultSecurityId
  );
  return defaultSecurity;
};

export const getRetirementProjectionData = ({
  retirementProjection,
  employeeDateOfBirth,
}) => {
  const RETIREMENT_AGE = 65;
  const YEARS_TO_SPEND = 30;
  const retirementYear = format(
    add(new Date(employeeDateOfBirth), { years: RETIREMENT_AGE }),
    "yyyy"
  );

  const amountToSpendPerMonth = formatCurrency({
    amount: retirementProjection / (YEARS_TO_SPEND * 12),
    digits: 0,
  });
  return {
    retirementYear,
    amountToSpendPerMonth,
    RETIREMENT_AGE,
    YEARS_TO_SPEND,
  };
};

export const getEmployeeFAQ = (t) => [
  {
    text: t("dashboard:dashboard.employee.support.faq.penelope401KOverview"),
    href: "/401k-benefits",
  },
  {
    text: t("dashboard:dashboard.employee.support.faq.retirementPlanFeatures"),
    href: "/your-retirement-plan",
  },
  {
    text: t("dashboard:dashboard.employee.support.faq.updateDeferralElection"),
    href: "/update-your-deferral-election",
  },
  {
    text: t("dashboard:dashboard.employee.support.faq.updateInvestmentAllocation"),
    href: "/update-your-investment-allocation",
  },
  {
    text: t("dashboard:dashboard.employee.support.faq.updatePassword"),
    href: "/update-password",
  },
  {
    text: t("dashboard:dashboard.employee.support.faq.viewDocuments"),
    href: "/review-your-notifications-documents",
  },
  {
    text: t("dashboard:dashboard.employee.support.faq.reviewBeneficiary"),
    href: "/update-your-beneficiary",
  },
  {
    text: t("dashboard:dashboard.employee.support.faq.updatePersonalInformation"),
    href: "/update-your-personal-information",
  },
  {
    text: t("dashboard:dashboard.employee.support.faq.howToRollover"),
    href: "/your-rollover-guide",
  },
];

export const employeeDashboardMenuOptions = (t) => [
  {
    id: "main",
    name: t("dashboard:dashboard.employee.sideMenu.myDashboard"),
    href: "/individual/main",
    icon: AccountIcon,
  },
  {
    id: "investment",
    name: t("dashboard:dashboard.employee.sideMenu.myInvestment"),
    href: "/individual/investment",
    icon: InvestmentsIcon,
  },
  {
    id: "contributions",
    name: t("dashboard:dashboard.employee.sideMenu.contributions"),
    href: "/individual/contributions",
    icon: PayrollIcon,
  },
  {
    id: "documents",
    name: t("dashboard:dashboard.employee.sideMenu.myDocuments"),
    href: "/individual/documents",
    icon: DocumentsIcon,
  },
  {
    id: "support",
    name: t("dashboard:dashboard.employee.sideMenu.support"),
    href: "/individual/support",
    icon: SupportIcon,
  },
  {
    id: "settings",
    name: t("dashboard:dashboard.employee.sideMenu.settings"),
    href: "/individual/settings",
    icon: MyAccountIcon,
  },
];
