import { useNavigate } from "react-router-dom";
import { Body2 } from "../typography";
import twMerge from "~/utils/tw-merge";
import Logo from "~/components/logo";

const Footer = ({ className = "", logoColor, items = [] }) => {
  const navigate = useNavigate();
  const footerItemClass = "mb-5 sm:mr-16 sm:mb-0 sm:last:mr-0 text-sm";

  return (
    <footer className={twMerge("mt-auto lg:w-[calc(100vw-17.5rem)]", className)}>
      <ul className="mt-25 flex flex-col items-start pt-8 sm:flex-row sm:items-center ">
        <li>
          <button className="h-12 w-12" onClick={() => navigate("/")}>
            <Logo type="penelope" variant="small" className={`h-12 w-12 ${logoColor}`} />
          </button>
        </li>
        <div className="mt-8 flex flex-col sm:mt-0 sm:ml-auto sm:flex-row">
          {items.map(({ label, href }) => (
            <li className={footerItemClass} key={label}>
              <a href={href} target="_blank" rel="noreferrer" className="text-sm">
                <Body2>{label}</Body2>
              </a>
            </li>
          ))}
          <li className={footerItemClass}>
            <Body2>{`© ${new Date().getFullYear()} Penelope`}</Body2>
          </li>
        </div>
      </ul>
    </footer>
  );
};

export default Footer;
