import { useState, useEffect, useMemo } from "react";
import has from "lodash/has";
import isNil from "lodash/isNil";
import { useTranslation } from "react-i18next";
import { Body2 } from "../typography";

const genErrorMessages = (t, error) => ({
  required: error?.message || t("form.fieldIsRequired"),
  email: t("form.invalidEmail"),
  url: t("form.invalidURL"),
  phone: t("form.invalidPhone"),
});

export default function ErrorMessage({ error, className = "" }) {
  const [errorMessage, setErrorMessage] = useState(error?.message);
  const { t } = useTranslation();
  const ERROR_MESSAGES = useMemo(() => genErrorMessages(t, error), [error, t]);

  useEffect(() => {
    const updateErrorMessage = () => {
      const message =
        !isNil(error?.type) && has(ERROR_MESSAGES, error.type)
          ? ERROR_MESSAGES[error.type]
          : error?.message;
      setErrorMessage(message);
    };

    updateErrorMessage();
  }, [ERROR_MESSAGES, error]);

  return errorMessage ? (
    <Body2 className={`text-error-main pointer-events-none w-11/12 ${className}`}>
      {errorMessage}
    </Body2>
  ) : null;
}
