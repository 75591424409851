import { createSlice } from "@reduxjs/toolkit";
import merge from "lodash/merge";

export const initialState = {};

export const leadFunnelSlice = createSlice({
  name: "lead_funnel",
  initialState,
  reducers: {
    updateLeadFunnel: (state, action) => {
      state = merge(state, action.payload);
    },
    resetLeadFunnel: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateLeadFunnel, resetLeadFunnel } = leadFunnelSlice.actions;

export default leadFunnelSlice.reducer;
