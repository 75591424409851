import { createSlice } from "@reduxjs/toolkit";
import merge from "lodash/merge";

export const initialState = {};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    updateError: (state, action) => {
      state = {
        ...merge(state, action.payload),
      };
    },
    clearError: () => initialState,
  },
});

export const { updateError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
