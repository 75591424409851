import React from "react";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { clearError } from "../redux/error-slice";
import { signOut } from "../helpers";
import Button from "./form/button/new-button";

export default function Error({
  code = 404,
  message,
  description,
  canGoHome = true,
  isInvalidPath = false,
  stack,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const onSignOut = async () => {
    await signOut();
    navigate("/");
  };

  const goHome = () => {
    if (location.pathname !== "/") {
      dispatch(clearError());
      navigate("/");
    }
  };

  return (
    <div className="m-auto block w-full max-w-xl text-center text-text-secondary-main">
      {code !== "network" && <h1 className="mb-6 text-6xl font-thin">{code}(k)</h1>}
      <h2 className="mb-6 text-3xl">
        {message || t([`errors.statusCodes.${code}.title`, "errors.pageNotFound.title"])}
      </h2>

      {stack ? (
        <div className="-mx-60 whitespace-pre text-left">{stack}</div>
      ) : (
        <span className="mb-6 whitespace-pre-line text-sm">
          {isNil(description)
            ? t([
                `errors.statusCodes.${code}.description`,
                "errors.pageNotFound.description",
              ])
            : description}
        </span>
      )}
      <div className={`mt-8 flex justify-center gap-5 ${isInvalidPath && "invisible"}`}>
        {canGoHome && (
          <Button color="employer" variant="tertiary" onClick={goHome}>
            {t("common:general.goHome")}
          </Button>
        )}
        <Button color="employer" variant="tertiary" onClick={onSignOut}>
          {t("common:general.signout")}
        </Button>
      </div>
    </div>
  );
}

Error.propTypes = {
  code: PropTypes.number,
  message: PropTypes.string,
  description: PropTypes.string,
  canGoHome: PropTypes.bool,
  isInvalidPath: PropTypes.bool,
};
