import { useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";

export const companyQueryKey = (companyId: number | string) => ["companies", companyId];

const fetchCompany = async (companyId: number | string) => {
  const { data } = await openapi.get("/api/v1/companies/{company_id}", {
    params: {
      path: {
        company_id: Number(companyId),
      },
    },
  });

  return data;
};

export const useCompany = (companyId: number | string, suspense: boolean = false) => {
  return useQuery({
    queryKey: companyQueryKey(companyId),
    queryFn: () => fetchCompany(companyId),
    enabled: !!companyId,
    useErrorBoundary: true,
    suspense,
  });
};

export default useCompany;
