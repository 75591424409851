import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currentUserSelector } from "../redux/selectors";

const useScript = ({ src, defer = true, async = true, id }) => {
  const user = useSelector(currentUserSelector);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    if (!src) {
      return;
    }

    window.hsConversationsSettings = {
      loadImmediately: false,
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.async = async;
    script.defer = defer;
    if (id) {
      script.id = id;
    }
    document.body.appendChild(script);
    script.onload = () => setIsScriptLoaded(true);

    return () => {
      document.body.removeChild(script);
    };
  }, [async, defer, id, src]);

  useEffect(() => {
    if (isScriptLoaded) {
      if (user.email) {
        window.hsConversationsSettings.identificationEmail = user.email;
      }
      window.hsConversationsOnReady = [
        () => window.HubSpotConversations.widget.load(),
      ];
      if (window.HubSpotConversations?.widget.status === "loaded") {
        window.HubSpotConversations.widget.refresh();
      }
    }
  }, [isScriptLoaded, user.email]);
};

export default useScript;
