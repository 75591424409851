import { useTranslation } from "react-i18next";
import { LogoutButton, NavMenuItem } from "./menu-item";
import { signOut } from "../../helpers";
import LogoutIcon from "~/assets/icons/logout-icon";
import CloseIcon from "./close-icon";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PhoneSidebar = ({ navigation, setSidebarOpen }) => {
  const { t } = useTranslation();
  return (
    <div className="flex h-0 flex-1 flex-col overflow-y-auto">
      <button className="pl-4 pt-4 pb-5" onClick={() => setSidebarOpen(false)}>
        <CloseIcon />
      </button>
      <nav className="space-y-1 px-2">
        {navigation.map((item) => (
          <NavMenuItem key={item.name} item={item} />
        ))}
      </nav>
      <div className="mt-auto mb-10 px-2">
        <LogoutButton
          item={{
            id: "logout",
            name: t("common:general.signOut"),
            icon: LogoutIcon,
            onClick: signOut,
          }}
        />
      </div>
    </div>
  );
};

export default PhoneSidebar;
