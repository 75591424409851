import * as yup from "yup";
import { FundPackageNames, FundTypes } from "../types";
import { addressSchema, phoneSchema } from "./general";

export const employeePersonalInfoSchema = yup
  .object({
    email: yup.string().email().required(),
    phone_nr: phoneSchema,
    marital_status: yup
      .mixed()
      .oneOf(["single", "married"], "Required field")
      .required("Required field"),
  })
  .required();

export const employeeInfoWithAddressSchema = yup
  .object({
    email: yup.string().email().required(),
    phone_nr: phoneSchema,
    marital_status: yup
      .mixed()
      .oneOf(["single", "married"], "Required field")
      .required("Required field"),
    address: addressSchema.required(),
  })
  .required();

export const employeeBeneficiary️Schema = yup
  .object()
  .shape({
    id: yup.string(),
    beneficiary_type: yup
      .mixed()
      .oneOf(["spouse", "individual", "charity", "estate", "trust", "domesticpartner"])
      .required(),
    sub_beneficiary_type: yup.mixed(),
    invited: yup.boolean().default(false).required(),
  })
  .required();

export const spouseBeneficiaryTypeSchema = employeeBeneficiary️Schema
  .shape({
    name: yup.string().required(),
    date_of_birth: yup.string().required(),
  })
  .required();

export const domesticPartnerBeneficiaryTypeSchema = employeeBeneficiary️Schema
  .shape({
    name: yup.string().required(),
    date_of_birth: yup.string().required(),
  })
  .required();

export const individualBeneficiaryTypeSchema = employeeBeneficiary️Schema
  .shape({
    name: yup.string().required(),
    date_of_birth: yup.string().required(),
    relationship_type: yup
      .mixed()
      .oneOf(["child", "grandchild", "otherfamily"])
      .required(),
  })
  .required();

export const charityBeneficiaryTypeSchema = employeeBeneficiary️Schema
  .shape({
    name: yup.string().required(),
    cin: yup.string(), // FIXME: add regex for match format
  })
  .required();

export const estateBeneficiaryTypeSchema = employeeBeneficiary️Schema
  .shape({
    ein: yup.string().required(), // FIXME: add regex for match format
  })
  .required();

export const trustUnderAgreementSchema = employeeBeneficiary️Schema
  .shape({
    name: yup.string().required(),
    date_of_birth: yup.string().required(),
    tin: yup.string().required(), // FIXME: add regex for match format
  })
  .required();

export const trustUnderMyLastWillSchema = employeeBeneficiary️Schema
  .shape({
    name: yup.string().required(),
    section_of_will: yup.string().required(),
    tin: yup.string().required(), // FIXME: add regex for match format
  })
  .required();

export const employeePercentageSchema = yup
  .object({
    allocation: yup.number().min(0).max(1),
    percentage: yup.number().min(1).max(100),
  })
  .required();

export const employeeBeneficiaryiesSchema = yup.object({
  beneficiaries: yup.array().of(employeePercentageSchema),
});

export const investmentSchema = yup
  .object({
    pre_tax: yup
      .number()
      .required()
      .min(0)
      .test("max", function (value) {
        const { roth } = this.parent;
        const maxContributionPercentage = this.options.context.maxContributionPercentage;
        if (value <= Number((maxContributionPercentage - roth).toFixed(4))) {
          return true;
        }
        return this.createError({
          message: `Total should not exceed ${maxContributionPercentage * 100}%`,
        });
      }),
    roth: yup
      .number()
      .required()
      .min(0)
      .test("max", function (value) {
        const { pre_tax } = this.parent;
        const maxContributionPercentage = this.options.context.maxContributionPercentage;
        if (value <= Number((maxContributionPercentage - pre_tax).toFixed(4))) {
          return true;
        }
        return this.createError({
          message: `Total should not exceed ${maxContributionPercentage * 100}%`,
        });
      }),
  })
  .required();

export const fundSchema = yup
  .object({
    fundType: yup
      .string()
      .oneOf(Object.values(FundTypes))
      .default(() => "dateFund")
      .required(),
    packageName: yup
      .string()
      .oneOf([...Object.values(FundPackageNames), null])
      .nullable(true)
      .when("fundType", (fundType, schema) => {
        return fundType === "dateFund" ? schema.required() : schema.notRequired();
      }),
    elections: yup
      .array()
      .of(
        yup.object().shape({
          allocation: yup.number(),
        })
      )
      .nullable(true)
      .when("fundType", (fundType, schema) => {
        return fundType === "ownFunds" ? schema.required() : schema.notRequired();
      }),
  })
  .required();

export const genericBeneficiarySchema = yup.object({
  beneficiaries: yup.lazy((allValues) =>
    yup.array().of(
      yup.lazy((value) => {
        let schema;
        if (value.beneficiary_type === "spouse") {
          schema = spouseBeneficiaryTypeSchema;
        } else if (value.beneficiary_type === "domesticpartner") {
          schema = domesticPartnerBeneficiaryTypeSchema;
        } else if (value.beneficiary_type === "individual") {
          schema = individualBeneficiaryTypeSchema;
        } else if (value.beneficiary_type === "charity") {
          schema = charityBeneficiaryTypeSchema;
        } else if (value.beneficiary_type === "estate") {
          schema = estateBeneficiaryTypeSchema;
        } else if (
          value.beneficiary_type === "trust" &&
          value.sub_beneficiary_type === "under-agreement"
        ) {
          schema = trustUnderAgreementSchema;
        } else if (
          value.beneficiary_type === "trust" &&
          value.sub_beneficiary_type === "under-my-last-will"
        ) {
          schema = trustUnderMyLastWillSchema;
        } else {
          schema = employeeBeneficiary️Schema;
        }
        return value.is_primary
          ? schema
          : schema.shape({
              allocation: yup
                .number()
                .typeError("must be a number")
                .required()
                .test(
                  "allocation",
                  "All contingent allocations must add up to 100%",
                  () => {
                    const allocations = allValues
                      .filter((v) => !v.is_primary)
                      .map((v) => Number(v.allocation));

                    if (!allocations.every((a) => a > 0 && a <= 1)) {
                      return false;
                    }

                    const sum = allocations.reduce((acc, curr) => (acc += curr), 0);
                    if (sum !== 1) {
                      return false;
                    }
                    return true;
                  }
                ),
            });
      })
    )
  ),
});
