import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import { currentUserSelector } from "../../redux/selectors";
import Button from "@mui/joy/Button";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Close from "@mui/icons-material/Close";
const cookies = new Cookies();

const getNakedDomain = () => {
  return document.domain.replace(/[\w\-]+.penelope./, "penelope.");
};

const getCookieOptions = () => {
  const domain = document.domain !== "localhost" ? "." + getNakedDomain() : null;
  return { maxAge: 12 * 60 * 60, path: "/", ...(domain ? { domain } : {}) };
};

const redirect = (subDomain, newWindow = false, path = "/") => {
  if (document.domain === "localhost") {
    document.location.pathname = path;
    return;
  }
  const proto = document.location.protocol;
  const port = document.location.port;
  const isNavia =
    document.location.host.startsWith("navia-admin.") ||
    document.location.host.startsWith("navia.");

  if (isNavia) {
    subDomain = { admin: "navia-admin", app: "navia" }[subDomain];
  }

  const newLocation = `${proto}//${subDomain}.${getNakedDomain()}`;
  const url = !port ? newLocation : `${newLocation}:${port}`;
  if (!newWindow) window.location = url;
  else window.open(url, newWindow ? "_blank" : "_self").focus();
};

function UserHijack(props) {
  const user = useSelector(currentUserSelector);
  // Hide by default on staging for demo's
  const [hidden, setHidden] = useState(document.domain === "app.penelope.run");
  const stopSession = async () => {
    const cookieOptions = getCookieOptions(); // Yes, browsers are strict: removal requires the same options
    if (cookies.get("hijack").includes("user")) {
      redirect("admin", false, localStorage.getItem("hijackOriginUrl") || "/");
      cookies.remove("hijack", cookieOptions);
      localStorage.removeItem("hijackOriginUrl");
    } else {
      redirect("admin", false, localStorage.getItem("hijackEmployerUrl") || "/");
      cookies.set("hijack", localStorage.getItem("hijackUserName"));
      localStorage.removeItem("hijackUserName");
      localStorage.removeItem("hijackEmployerUrl");
    }
  };

  useEffect(() => {
    const stagingSwitcher = async (evt) => {
      if (evt.metaKey && evt.shiftKey && evt.key === "Escape") await stopSession();
    };

    window.addEventListener("keydown", stagingSwitcher);
    return () => window.removeEventListener("keydown", stagingSwitcher);
  }, []);

  const isHijacked = !!user.realUserId;
  return isHijacked && !hidden ? (
    <Sheet sx={{ padding: "10px 20px", my: 2, borderRadius: "0.5rem" }}>
      <Stack spacing={2} sx={{ display: "flex", alignItems: "center" }} direction={"row"}>
        <Typography>
          You're temporarily logged in as {user.email} (company #{user.company_id})
        </Typography>
        <Button variant="outlined" onClick={stopSession}>
          Back to admin
        </Button>
        <IconButton variant="plain" onClick={() => setHidden(true)}>
          <Close />
        </IconButton>
      </Stack>
    </Sheet>
  ) : null;
}

const hijack = async ({ type, id }, newWindow) => {
  const cookieOptions = getCookieOptions();
  cookies.set("hijack", `${type}${id}`, cookieOptions);
  if (type === "user") {
    localStorage.setItem("hijackUserName", `${type}${id}`);
  }
  redirect("app", newWindow);
};

export default UserHijack;
export const hijackEmployee = ({ id, newWindow = false }) =>
  hijack({ type: "employee", id }, newWindow);
export const hijackUser = ({ id, newWindow = false }) =>
  hijack({ type: "user", id }, newWindow);
