import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider } from "react-query";
import { Loading } from "./components";
import App from "./App";
import store from "./redux/store";
import "./i18n";
import { queryClient } from "./services/query-client";
import { ReRenderProvider } from "./context/re-render";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading fullScreen />}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ReRenderProvider>
            <App />
          </ReRenderProvider>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
