import { createContext, useState, useContext } from "react";

const ReRenderContext = createContext(null);

export const ReRenderProvider = ({ children }) => {
  const [key, setKey] = useState(0);

  const triggerReRender = () => {
    setKey((prevKey) => prevKey + 1);
  };

  return (
    <ReRenderContext.Provider value={{ triggerReRender }}>
      <div key={key}>{children}</div>
    </ReRenderContext.Provider>
  );
};

export const useReRender = () => {
  return useContext(ReRenderContext);
};
