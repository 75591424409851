import isNil from "lodash/isNil";
import { useQueries } from "react-query";
import { useSelector } from "react-redux";
import { currentUserSelector } from "~/redux/selectors";
import { getFileUrl } from "~/services/api";
import { ReactComponent as NaviaDarkLogoFullSVG } from "~/assets/images/navia-dark.svg";
import { ReactComponent as NaviaLogoFullSVG } from "~/assets/images/navia.svg";
import { ReactComponent as BankOfAmericaLogoFull } from "~/assets/images/bank-of-america.svg";
import { ReactComponent as BankOfAmericaLogo } from "~/assets/images/bank-of-america-compact.svg";
import useCompany from "~/hooks/dashboards/queries/use-company";
import { NaviaLogoSmall } from "./icons/navia-logo-small";
import { getActiveTheme } from "~/utils";
import { ACMEBenefitsFull, ACMEBenefitsSmall } from "~/assets/logos/acme-benefits";

const PenelopeLogoFull = (props) => (
  <svg
    height="100%"
    width={122}
    viewBox="0 0 203 53"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="svg-path"
      d="M27.0322 0C17.3213 0 9.32589 7.39378 8.4917 16.7937H12.9407C13.7535 9.81888 19.764 4.3804 27.0322 4.3804C34.8565 4.3804 41.2178 10.6738 41.2178 18.4146C41.2178 26.1555 34.8565 32.4489 27.0322 32.4489C25.5991 32.4489 24.213 32.2372 22.904 31.8436V36.3764C24.2344 36.6727 25.6119 36.8293 27.0322 36.8293C37.2949 36.8293 45.6454 28.5679 45.6454 18.4146C45.6454 8.2614 37.2949 0 27.0322 0Z"
    />
    <path
      className="svg-path"
      d="M12.8466 18.7575V18.7406V18.4147H12.5172H8.74406H8.41466V18.7363V18.7533C8.41038 23.9293 5.00088 29.0631 0 31.2342V35.9236C4.59876 34.3873 7.05428 32.0511 8.41894 29.5794V52.0613V52.3829H8.74834H12.5215H12.8509V52.057V29.5794C14.2155 32.0511 16.671 34.3873 21.2698 35.9236V31.2342C16.2646 29.0631 12.8551 23.9293 12.8466 18.7575Z"
    />
    <path
      className="svg-path"
      d="M75.2701 14.03C75.2701 18.859 71.7237 21.61 66.6543 21.61H61.7732V32.8128H58.3809V6.41187H66.6543C71.7237 6.41187 75.2701 9.20094 75.2701 14.03ZM61.7732 18.5204H66.6543C69.7045 18.5204 71.8392 16.8614 71.8392 14.03C71.8392 11.1266 69.7045 9.50566 66.6543 9.50566H61.7732V18.5204Z"
    />
    <path
      className="svg-path"
      d="M75.2188 23.65C75.2188 18.1819 79.1844 14.0681 84.4462 14.0681C89.6696 14.0681 93.4427 18.2919 93.4427 23.4976C93.4427 24.2129 93.4042 24.6657 93.4042 24.6657H78.4956C78.7224 27.9076 81.5073 30.3243 84.7115 30.3243C87.4964 30.3243 88.9038 29.0038 90.0888 27.1924L92.7582 28.551C91.2353 31.3062 88.4119 33.2276 84.6772 33.2276C79.1801 33.2318 75.2188 29.2323 75.2188 23.65ZM90.123 21.9909C89.7808 19.0876 87.4151 16.8995 84.3649 16.8995C81.3533 16.8995 78.8764 19.5024 78.5684 21.9909H90.123Z"
    />
    <path
      className="svg-path"
      d="M99.2217 32.8171H95.9448V14.4829H99.2217V17.1238C100.364 15.3124 102.349 14.0681 105.13 14.0681C109.857 14.0681 112.603 17.2 112.603 22.0629V32.8129H109.326V22.4438C109.326 19.1638 107.611 17.0857 104.445 17.0857C101.279 17.0857 99.2217 19.4981 99.2217 23.2352V32.8171Z"
    />
    <path
      className="svg-path"
      d="M114.999 23.65C114.999 18.1819 118.964 14.0681 124.226 14.0681C129.45 14.0681 133.223 18.2919 133.223 23.4976C133.223 24.2129 133.184 24.6657 133.184 24.6657H118.276C118.502 27.9076 121.287 30.3243 124.491 30.3243C127.276 30.3243 128.684 29.0038 129.869 27.1924L132.538 28.551C131.011 31.3062 128.192 33.2276 124.457 33.2276C118.964 33.2318 114.999 29.2323 114.999 23.65ZM129.907 21.9909C129.565 19.0876 127.199 16.8995 124.149 16.8995C121.138 16.8995 118.661 19.5024 118.353 21.9909H129.907Z"
    />
    <path className="svg-path" d="M139.054 6.41187V32.817H135.777V6.41187H139.054Z" />
    <path
      className="svg-path"
      d="M161.012 23.65C161.012 28.97 156.893 33.2319 151.327 33.2319C145.761 33.2319 141.642 28.97 141.642 23.65C141.642 18.33 145.761 14.0681 151.327 14.0681C156.897 14.0681 161.012 18.33 161.012 23.65ZM144.962 23.65C144.962 27.3067 147.708 30.2523 151.327 30.2523C154.95 30.2523 157.693 27.3109 157.693 23.65C157.693 19.9891 154.946 17.0476 151.327 17.0476C147.708 17.0476 144.962 19.9891 144.962 23.65Z"
    />
    <path
      className="svg-path"
      d="M184.776 23.65C184.776 18.1819 188.742 14.0681 194.004 14.0681C199.227 14.0681 203 18.2919 203 23.4976C203 24.2129 202.962 24.6657 202.962 24.6657H188.053C188.28 27.9076 191.065 30.3243 194.269 30.3243C197.054 30.3243 198.461 29.0038 199.646 27.1924L202.316 28.551C200.789 31.3062 197.969 33.2276 194.235 33.2276C188.738 33.2318 184.776 29.2323 184.776 23.65ZM199.681 21.9909C199.338 19.0876 196.973 16.8995 193.923 16.8995C190.911 16.8995 188.434 19.5024 188.126 21.9909H199.681Z"
    />
    <path
      className="svg-path"
      d="M172.917 14.0681C167.352 14.0681 163.232 18.33 163.232 23.65V40.6595H166.475V30.0619C167.844 32.0637 170.385 33.2319 172.917 33.2319C178.483 33.2319 182.603 28.97 182.603 23.65C182.598 18.33 178.483 14.0681 172.917 14.0681ZM172.917 30.2481C169.294 30.2481 166.552 27.3067 166.552 23.6458C166.552 19.9848 169.298 17.0434 172.917 17.0434C176.541 17.0434 179.283 19.9848 179.283 23.6458C179.283 27.3067 176.536 30.2481 172.917 30.2481Z"
    />
  </svg>
);

const PenelopeLogo = (props) => (
  <svg
    width={28}
    height={30}
    viewBox="0 0 28 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_332_17457)">
      <path d="M6.96111 10.8394H4.55654C4.27611 14.1469 2.32103 16.2094 0 17.1044V19.6193C1.78403 19.0395 3.85745 17.6732 4.56052 16.3595V28.6837H6.96111V16.3585C7.66319 17.6722 9.7376 19.0385 11.5206 19.6183V17.1044C9.19861 16.2094 7.24552 14.1469 6.96111 10.8394Z" />
      <path d="M14.6095 0C12.0673 0.00333109 9.61996 0.965435 7.75607 2.6942C5.89219 4.42296 4.74897 6.79115 4.55469 9.3259H6.96124C7.11579 7.77791 7.73668 6.31332 8.74177 5.12591C9.74685 3.93849 11.0888 3.0842 12.59 2.67607C14.0911 2.26794 15.6809 2.32521 17.1488 2.84029C18.6168 3.35536 19.8937 4.30398 20.8108 5.56064C21.7278 6.81729 22.2417 8.32276 22.2845 9.87785C22.3273 11.433 21.8969 12.9644 21.0503 14.2696C20.2037 15.5747 18.9809 16.5921 17.5435 17.1871C16.106 17.7821 14.5219 17.9267 13.0005 17.6017V19.9784C13.5326 20.064 14.0706 20.1069 14.6095 20.1067C20.1695 20.1067 24.6932 15.6466 24.6932 10.0867C24.6908 7.41275 23.6278 4.849 21.7373 2.95798C19.8469 1.06697 17.2834 0.00315959 14.6095 0Z" />
    </g>
    <defs>
      <clipPath id="clip0_332_17457">
        <rect width={24.7617} height={28.64} />
      </clipPath>
    </defs>
  </svg>
);

const logos = {
  penelope: {
    full: ({ dark, ...props }) => <PenelopeLogoFull {...props} />,
    small: ({ dark, ...props }) => <PenelopeLogo {...props} />,
  },
  navia: {
    full: ({ dark, ...props }) =>
      dark ? <NaviaDarkLogoFullSVG {...props} /> : <NaviaLogoFullSVG {...props} />,
    small: ({ dark, ...props }) => <NaviaLogoSmall {...props} />,
  },
  bankOfAmerica: {
    full: ({ dark, ...props }) => <BankOfAmericaLogoFull {...props} />,
    small: ({ dark, ...props }) => <BankOfAmericaLogo {...props} />,
  },
  acmeBenefits: {
    full: ({ dark, ...props }) => <ACMEBenefitsFull {...props} />,
    small: ({ dark, ...props }) => null,
  },
};

const toSrc = ({ data, config }) => {
  const isSvg = config.url.endsWith(".svg");
  const svgBlob = isSvg ? new Blob([data], { type: "image/svg+xml" }) : null;
  const src = URL.createObjectURL(isSvg ? svgBlob : data);
  return src;
};

const Logo = ({ variant = "small", ...props }) => {
  const user = useSelector(currentUserSelector);
  const { data: company } = useCompany(user.company_id);
  const custom_logo_url = company?.target_data?.custom_logo;
  const custom_logo_url_short = company?.target_data?.custom_logo_short;
  const activeTheme = getActiveTheme({ company, user });
  const [logoQuery, compactLogoQuery] = useQueries([
    ...(custom_logo_url
      ? [
          {
            queryKey: ["custom_logo_url"],
            queryFn: () =>
              getFileUrl(custom_logo_url).then((res) =>
                toSrc({ data: res.data, config: res.config })
              ),
            keepPreviousData: true,
          },
        ]
      : []),
    ...(custom_logo_url_short
      ? [
          {
            queryKey: ["custom_logo_url_short"],
            queryFn: () =>
              getFileUrl(custom_logo_url_short).then((res) =>
                toSrc({ data: res.data, config: res.config })
              ),
            keepPreviousData: true,
          },
        ]
      : []),
  ]);

  if (activeTheme.isCustomLogo) {
    if (
      (variant === "full" && (logoQuery?.isLoading || logoQuery?.isError)) ||
      (variant === "small" && (compactLogoQuery?.isLoading || compactLogoQuery?.isError))
    ) {
      return null;
    }

    if (variant === "small" && !isNil(custom_logo_url_short)) {
      const src = compactLogoQuery.data;
      return <img src={src} alt="logo" width={28} height={30} {...props} />;
    }

    if (!isNil(custom_logo_url) && variant !== "small") {
      const src = logoQuery.data;
      return <img src={src} alt="logo" width={28} height={30} {...props} />;
    }
    return null;
  }

  const currentLogo = logos[activeTheme.logo] ? activeTheme.logo : "penelope";

  return logos[currentLogo][variant](props);
};

export default Logo;
