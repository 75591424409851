import orderBy from "lodash/orderBy";
import { QueryClient, UseQueryOptions, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";
import { OpenApiPathParams } from "~/types/openapi-params";

interface FetchEmployeeSuperAdminProps
  extends OpenApiPathParams<"get", "/api/v1/admin/employees/{employee_u_id}"> {}

export const employeeAdminQueryKey = ({ employeeUId }: FetchEmployeeSuperAdminProps) => [
  "admin",
  "employees",
  employeeUId,
];

export const fetchEmployeeSuperAdmin = async ({
  employeeUId,
}: FetchEmployeeSuperAdminProps) => {
  const { data } = await openapi.get("/api/v1/admin/employees/{employee_u_id}", {
    params: { path: { employee_u_id: employeeUId } },
  });

  return data;
};

type FetchEmployeeSuperAdminReturnType = Awaited<
  ReturnType<typeof fetchEmployeeSuperAdmin>
>;

interface EmployeeAdminQueryProps extends FetchEmployeeSuperAdminProps {
  queryOptions?: UseQueryOptions<
    FetchEmployeeSuperAdminReturnType,
    unknown,
    FetchEmployeeSuperAdminReturnType
  >;
}

const employeeAdminQuery = ({
  employeeUId,
  queryOptions = {},
}: EmployeeAdminQueryProps) => ({
  ...queryOptions,
  queryKey: employeeAdminQueryKey({ employeeUId }),
  queryFn: () => fetchEmployeeSuperAdmin({ employeeUId }),
});

export const employeeAdminLoader = (queryClient: QueryClient) => {
  return async ({ params }: { params: FetchEmployeeSuperAdminProps }) => {
    const query = employeeAdminQuery(params);

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};

export const useEmployeeAdmin = ({
  employeeUId,
  queryOptions = {},
}: EmployeeAdminQueryProps) => {
  return useQuery(employeeAdminQuery({ employeeUId, queryOptions }));
};

const getOrderedExternalBenefits = (data) => {
  return {
    data: orderBy(data.external_benefit_statuses, "created_at", "desc"),
  };
};

const getEmploymentPeriods = (data) => {
  return {
    data: data.employment_periods,
  };
};

export const useEmployeeExternalBenefits = ({ employeeUId }) => {
  return useQuery({
    queryFn: () => fetchEmployeeSuperAdmin({ employeeUId }),
    queryKey: ["admin", "employees", employeeUId, "external-benefits"],
    select: getOrderedExternalBenefits,
  });
};

export const useEmploymentPeriods = ({ employeeUId }) => {
  return useQuery({
    queryFn: () => fetchEmployeeSuperAdmin({ employeeUId }),
    queryKey: ["admin", "employees", employeeUId, "employment-periods"],
    select: getEmploymentPeriods,
  });
};
