import { useMemo } from "react";
import { ReactComponent as ProgressCircleTypeFive } from "../../assets/images/progress-circles/type-five.svg";
import { ReactComponent as ProgressCircleTypeFour } from "../../assets/images/progress-circles/type-four.svg";
import { ReactComponent as ProgressCircleTypeThree } from "../../assets/images/progress-circles/type-three.svg";

const variantTypeMap = {
  lola: "type-lola",
  mahogany: "type-mahogany",
};

export default function ProgressCircle({
  numberOfParts,
  activeIndex = 0,
  className = "w-20 h-20",
  variant = "",
}) {
  const commonProps = {
    className: `progress-circle ${variantTypeMap[variant]}`,
    "data-active": activeIndex,
  };

  const Component = useMemo(() => {
    switch (numberOfParts) {
      case 3:
        return ProgressCircleTypeThree;
      case 4:
        return ProgressCircleTypeFour;
      case 5:
        return ProgressCircleTypeFive;
      default:
        return null;
    }
  }, [numberOfParts]);

  return (
    <div className={`text-text-tertiary-light ${className}`}>
      <Component {...commonProps} />
    </div>
  );
}
