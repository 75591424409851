import { createSlice } from "@reduxjs/toolkit";
import merge from "lodash/merge";
import unionBy from "lodash/unionBy";

export const initialState = {
  admins: [null, null],
};

export const manageAdminSlice = createSlice({
  name: "manageAdmins",
  initialState,
  reducers: {
    addAdmin: (state, action) => {
      const admins = [...state.admins];
      admins[action.payload.index] = action.payload.data;
      state.admins = [...merge(state.admins, admins)];
    },
    updateAdmins: (state, action) => {
      state.admins = [...merge(state.admins, action.payload)];
    },
    unionUpdateAdmins: (state, action) => {
      state.admins = [...unionBy(action.payload, state.admins, "email")];
    },
  },
});

export const { addAdmin, updateAdmins, unionUpdateAdmins } =
  manageAdminSlice.actions;

export default manageAdminSlice.reducer;
