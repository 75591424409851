import { useLocation, useMatches } from "react-router-dom";

type Match = Omit<ReturnType<typeof useMatches>[number], "data" | "handle">;

function useMatch<Data = unknown, Handle = unknown>() {
  const location = useLocation();
  const matches = useMatches();
  const match = [...matches.reverse()].find(
    (match) => match.pathname === location.pathname
  );

  return match as (Match & { data?: Data; handle?: Handle }) | undefined;
}

export interface CustomRouteProps {
  title?: string;
}

export function usePageTitle() {
  const match = useMatch<unknown, CustomRouteProps>();
  return {
    title: match?.handle?.title,
  };
}
