import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { ListItemButtonProps } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import { useJoyTheme } from "~/hooks/use-joy-theme";

interface SidebarListItemProps extends ListItemButtonProps {
  icon: ReactNode;
  href?: string;
}

export const SidebarListItem: FC<SidebarListItemProps> = ({
  children,
  icon,
  href,
  ...props
}) => {
  const theme = useJoyTheme();

  return (
    <ListItem
      component={href ? Link : undefined}
      to={href}
      sx={{
        textDecoration: "none",
      }}
    >
      <ListItemButton
        variant={theme.sidebar.variant}
        color={theme.sidebar.color}
        selected={window.location.pathname === href}
        {...props}
      >
        {icon}
        <ListItemContent>
          <Typography level="title-sm" noWrap>
            {children}
          </Typography>
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  );
};
