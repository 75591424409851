import { createContext, useCallback, useContext, useState } from "react";

const BannerStateContext = createContext({});

export const BannerStateProvider = ({ children }) => {
  const [state, setState] = useState([]);
  const addMessage = useCallback(
    (message) =>
      setState((prev) =>
        !prev.some((m) => m.id === message.id) ? prev.concat(message) : prev
      ),
    []
  );

  const removeMessage = useCallback(
    (id) => setState((prev) => prev.filter((s) => s.id !== id)),
    []
  );

  const resetState = useCallback(() => setState([]), []);

  return (
    <BannerStateContext.Provider
      value={{
        state,
        addMessage,
        removeMessage,
        resetState,
      }}
    >
      {children}
    </BannerStateContext.Provider>
  );
};

export const useBanner = () => useContext(BannerStateContext);

export default BannerStateProvider;
