import last from "lodash/last";
import { Link, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { signOut } from "../helpers";
import ProgressCircle from "../components/progress-circle";
import Button from "../components/form/button/new-button";
import Logo from "~/components/logo";
import { TailwindCSSProvider } from "~/components/tailwind-css-provider";

function DefaultLayout({
  children,
  showProgressCircle = false,
  numberOfParts = 0,
  activeIndex = 0,
  logo = "default",
  className = "",
  tabs = [],
  activeTab = null,
  showSignOut = false,
  isRouter = false,
}) {
  const { t } = useTranslation();
  return (
    <TailwindCSSProvider>
      <div className={`min-h-screen w-full bg-layout-bg ${className}`}>
        <div
          className={`container mx-auto flex min-h-screen flex-col px-7 pb-6 text-text-secondary-main md:px-5 md:pb-12 ${className}`}
        >
          <div
            className={`box-border flex h-auto w-full justify-center pt-6 pb-4 md:h-36 md:py-8 ${
              logo === "white" ? "md:justify-start" : ""
            } items-center lg:justify-between`}
          >
            <div className="logo h-12">
              <Link to="/">
                <Logo
                  dark
                  type="penelope"
                  variant="full"
                  className="w-[122px] fill-pen-hay-01"
                />
              </Link>
            </div>
            {showProgressCircle && (
              <div className="progress-circle hidden lg:block">
                <ProgressCircle numberOfParts={numberOfParts} activeIndex={activeIndex} />
              </div>
            )}
            {showSignOut && (
              <Link to="/" onClick={signOut} className="ml-auto text-text-tertiary-main">
                {t("common:general.signOut")}
              </Link>
            )}
          </div>
          <div className="flex flex-grow flex-col">
            {isRouter ? <Outlet /> : children}
          </div>
          <div className="onboarding-width m-auto mt-0 max-w-md lg:max-w-full">
            {last(tabs)?.id !== activeTab?.id && (
              <Button
                variant="tertiary"
                color="neutral"
                className="mt-2.5 md:hidden"
                onClick={signOut}
              >
                {t("common:general.signOut")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </TailwindCSSProvider>
  );
}

export default DefaultLayout;
