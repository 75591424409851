import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0,
  enabled: !!import.meta.env.VITE_SENTRY_DSN,
  ignoreErrors: ["Failed to fetch dynamically imported module"],
});

export default Sentry;
