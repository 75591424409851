import { QueryClient, useQuery } from "react-query";
import get from "lodash/get";
import mapValues from "lodash/mapValues";
import transform from "lodash/transform";
import apiInstance from "~/services/axios-instance";

const SCHEMA_STALE_TIME_MS = 1000 * 60 * 60 * 24;

const fetchSchema = async () => {
  const { data } = await apiInstance.get(`/openapi.json`);

  return data;
};

/**
 * This function will recursively crawl through a schema and
 * replace all references with their literal values.
 *
 * For example, this:
 *
 * "EmployeeCreate": {
 *   properties: {
 *     "employee_status": {
 *       "$ref": "#/components/schemas/EmployeeStatus"
 *     }
 *   }
 * }
 *
 * becomes this:
 *
 * "EmployeeCreate": {
 *   properties: {
 *     "employee_status": {
 *       "title": "EmployeeStatus",
 *       "enum": [
 *         "pending",
 *         "invited",
 *         "activated",
 *         "terminated",
 *         "retired"
 *       ],
 *       "type": "string",
 *       "description": "An enumeration."
 *     }
 *   }
 * }
 */
const expandRefs = (rootSchema: any, value: any) => {
  if (Array.isArray(value)) {
    return value.map((v) => expandRefs(rootSchema, v));
  } else if (typeof value === "object" && value !== null) {
    /**
     * The API sometimes puts a list of schemas inside an
     * `allOf` property. This makes it difficult to reason about
     * when table filters from the schema.
     *
     * At the time of writing, `allOf` always consists of a
     * single schema, so in this code, we pluck the first
     * element from the array and remove the `allOf` property
     * to make things easier elsewhere in the code.
     */
    if ("allOf" in value) {
      const { allOf, ...rest } = value;

      return {
        ...expandRefs(rootSchema, value.allOf[0]),
        ...expandRefs(rootSchema, rest),
      };
    }

    if ("$ref" in value) {
      const reference = get(
        rootSchema,
        value.$ref.replace("#/", "").replaceAll("/", ".")
      );

      return expandRefs(rootSchema, reference);
    }

    return transform(value, (values, v, k) => {
      values[k] = expandRefs(rootSchema, v);
    });
  }

  return value;
};

const normalizePathKey = (path: string) =>
  path
    .replace("/api/v1/", "")
    .replaceAll("_u_id}", "}") // e.g. admin/companies/{company_id} -> admin/companies/{company}
    .replaceAll("_id}", "}") // e.g. admin/companies/{company_id} -> admin/companies/{company}
    .replaceAll(/\{|\}/g, ""); // e.g. admin/companies/{company} -> admin/companies/company

const getSchema = async () => {
  const rawSchema = await fetchSchema();

  const components = mapValues(
    get(rawSchema, "components.schemas", {}),
    (componentSchema) => expandRefs(rawSchema, componentSchema)
  );

  const paths: Record<string, any> = transform(
    get(rawSchema, "paths", {}),
    (pathSchemas, pathSchema, path: string) => {
      const key = normalizePathKey(path);

      pathSchemas[key] = expandRefs(rawSchema, pathSchema);
    },
    {}
  );

  return {
    components,
    paths,
  };
};

const transformSchema = (schema: any) => {
  // TODO remove: https://linear.app/penelope/issue/PEN-1672/change-alert-group-severity-to-a-string-enum
  const { properties: alertGroupProperties } = schema.components.AlertGroup;
  alertGroupProperties.severity.type = "string";
  alertGroupProperties.severity.enum = ["low", "medium", "high"];

  // TODO remove: https://linear.app/penelope/issue/PEN-1694/create-status-field-for-alert-groups
  alertGroupProperties.status = {
    title: "Status",
    type: "object",
    properties: {
      deadline: {
        title: "Deadline",
        type: "string",
        format: "date",
      },
    },
  };

  return schema;
};

const schemaQuery = () => ({
  queryKey: ["api_schema_2"],
  queryFn: getSchema,

  enabled: true,
  refetchOnMount: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: SCHEMA_STALE_TIME_MS,
  useErrorBoundary: true,
  select: transformSchema,
});

export const schemaLoader = (queryClient: QueryClient) => {
  return async () => {
    const query = schemaQuery();

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};

export const useSchema = () => {
  const { data: schema, ...rest } = useQuery(schemaQuery());

  return {
    ...rest,
    schema,
  };
};
