import { FC, PropsWithChildren, useEffect } from "react";
import css from "../css/index.css?inline";

/**
 * This component dynamically adds/removes Tailwind. The super admin
 * section of the site should not have Tailwind included, so this is
 * used to wrap the other sections of the website, which do rely on
 * Tailwind.
 */

export const TailwindCSSProvider: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    let styleElement = document.createElement("style");

    styleElement.textContent = css;
    document.head.appendChild(styleElement);

    return () => {
      styleElement.remove();
    };
  }, []);

  return <>{children}</>;
};
